body {
  font-family: 'Montserrat', sans-serif;
}

.centerTextDiv {
  text-align: center;
}

.bannerpage {
  position: relative;
  /* para poder posicionar el texto de forma absoluta */
  display: inline-block;
  /* para que solo ocupe el ancho de la imagen */
}

.righttextbanner {
  display: flex;
  flex-direction: row-reverse;
  padding: 3rem;
  /* width: 60%; */
  /* position: absolute; */
  /* posición absolute con respecto al padre */
  bottom: 3rem;
  /* posicionada en la esquina inferior derecha */
  right: 1rem;
}

.righttextbanner-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.righttextbanner-text div h4{
  text-align: end;
}

.lefttextbanner {
  display: flex;
  flex-direction: row;
  padding: 3rem;
  /* width: 60%; */
  /* position: absolute; */
  /* posición absolute con respecto al padre */
  bottom: 3rem;
  /* posicionada en la esquina inferior izquierda */
  left: 1rem;
}

.lefttextbanner-text {
  display: flex;
  flex-direction: column;
  width: 100%;
}



.hrblue {
  /* display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden; */
  border: 0;
  opacity: 0.25;
  border-top: 3px solid;
  border-color: #24a3d8;
  /* margin-left: 0; */
}

.hrwhite {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border: 0;
  opacity: 0.25;
  border-top: 3px solid;
  border-color: #e6e8e9;
  margin-left: 0;
}

.imgblur {
  filter: blur(20px);
  -webkit-filter: blur(8px);
}

.imgfit {
  object-fit: cover;
}

.divsimulator {
  background-color: #3f4699;
  -webkit-border-bottom-right-radius: 300px;
  -moz-border-radius-bottomright: 300px;
  border-bottom-right-radius: 300px;
}

@media (max-width: 900px) {
  .leftsimulatortext {
    display: none;
  }

}