@media screen and (max-width: 425px) {
    .subtitle-banner{
        width: 75%;
    }
    .subtitle-banner span{
        font-size: small;
        font-weight: bold;
    }
}


@media screen and (min-width: 426px) {
    .subtitle-banner{
        width: 50%;
    }
    .subtitle-banner span{
        font-size: large;
        font-weight: bold;
    }
}
