.logo {
  margin: 3rem 0rem;
}

@media screen and (max-width: 320px) {
  .logo {
    width: 7rem;
    height: 2rem;
    margin: 1rem 0rem;
  }
}

@media screen and (max-width: 425px) and (min-width: 321px) {
  .logo {
    width: 9rem;
    height: 3rem;
    margin: 1.2rem 0rem;
  }
  .container_logos {
    display: block;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 426px) and (max-width: 2160px) {
  .container_logos {
    display: inline-flex;
    margin-bottom: 1rem;
  }
}
