@media only screen and (max-device-width: 1024px) {
  .banner_item {
    height: 400px;
  }
  .image_item {
    object-fit: fill;
    display: initial;
    width: auto;
  }
  .subtitle_margin {
    margin: 2rem 0rem;
  }
}

@media only screen and (min-width: 1025px) {
  .banner_item {
    height: auto;
  }
  .image_item {
    object-fit: contain;
    display: block;
    width: 100%;
  }
  .subtitle_margin {
    margin: 0rem 0rem;
  }
}

